import { PurchaseOfferHeader } from '@pretto/bricks/website/shared/pages/ContentPage/components/PurchaseOfferHeader/PurchaseOfferHeader'

import { NAVBAR_HEIGHT } from '@pretto/website/src/components/Navbar/config/navbarHeight'
import { usePurchaseOfferHeader } from '@pretto/website/src/templates/offreAchat/lib/usePurchaseOfferHeader'
import { OffreAchat as OffreAchatView } from '@pretto/website/src/templates/offreAchat/views/OffreAchat/OffreAchat'
import { trackAction } from '@pretto/website/src/utilities/tracking'

import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'

export const OffreAchat = ({ data, ...props }) => {
  const purchaseOfferHeaderProps = usePurchaseOfferHeader()

  const purchaseOfferHeaderRef = useRef()

  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, false)

    return () => {
      document.removeEventListener('scroll', handleScroll, false)
    }
  }, [])

  const handleClickSidebarButton = () => {
    trackAction('PURCHASE_OFFER_WEB_CLICKED', { purchase_offer_web_option: 'sidebar' })
    window.open('https://app.pretto.fr/purchase-offer?signup_kind=sidebar')
  }

  const handleScroll = () => {
    if (purchaseOfferHeaderRef.current) {
      const isScrolled = purchaseOfferHeaderRef.current.getBoundingClientRect().bottom < NAVBAR_HEIGHT

      setIsHeaderScrolled(isScrolled)
    }
  }

  const headerComponent = useMemo(
    () => <PurchaseOfferHeader {...purchaseOfferHeaderProps} ref={purchaseOfferHeaderRef} title={data.title} />,
    [purchaseOfferHeaderProps]
  )

  return (
    <OffreAchatView
      {...props}
      headerComponent={headerComponent}
      isHeaderScrolled={isHeaderScrolled}
      onClickSidebarButton={handleClickSidebarButton}
    />
  )
}

OffreAchat.propTypes = {
  data: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired,
}

import Button from '@pretto/bricks/components/buttons/Button'
import { shape } from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import styled, { css } from 'styled-components'

export const Header = styled.div`
  ${column([2, 4])};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

export const Content = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([6, 8])};
  }
`

export const Page = styled.div`
  ${grid()};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`

export const Sidebar = styled.div`
  ${column([2, 4])};
  align-self: start;
  display: none;
  padding-right: ${g(3)};
  position: sticky;
  top: ${g(13)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`

export const SidebarPurchaseOffer = styled(Sidebar)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(4)} ${g(3)} ${g(30)};
  overflow: hidden;
  top: ${g(14)};
  opacity: 0.4;
  transition: opacity 250ms ease-in-out;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}

  ${shape(
    css`
      width: 651px;
      height: 747px;
      background-size: 651px 747px;
      transition: transform 250ms ease-in-out;
      transform: translate(211px, 513px);
      top: initial;
      left: initial;
    `,
    {
      variant: 'primary2',
      hover: {
        desktop: css`
          transform: translate(211px, 529px);
        `,
      },
    }
  )}
`

export const SidebarPurchaseOfferImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(15px, 260px);
  transition: transform 250ms ease-in-out;
  backface-visibility: hidden;

  ${SidebarPurchaseOffer}:hover & {
    transform: translate(15px, 235px);
  }
`

export const SidebarPurchaseOfferSheet = styled(Image).attrs({
  options: { width: 296, height: 417 },
  path: 'v1657033180/zen/static-assets/decoration/sample-purchase-offer.png',
})`
  width: 296px;
  height: 417px;
`

export const SidebarPurchaseOfferLabel = styled(Image).attrs({
  options: { height: 112, width: 112 },
  path: 'v1637684805/zen/static-assets/illustrations/660x660/label.svg',
})`
  display: block;
  width: 112px;
  height: 112px;
  position: absolute;
  top: ${g(-5)};
  right: ${g(-3)};
  transition: all 250ms ease-in-out;
  transform: translateY(0);

  ${SidebarPurchaseOffer}:hover & {
    transform: translateY(${g(1)});
  }
`

export const SidebarPurchaseOfferButton = styled(Button)`
  margin-top: ${g(2)};
`

export const SidebarPurchaseOfferTitle = styled.div`
  ${typo.heading32};
  margin-bottom: ${g(2)};
`

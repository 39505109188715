import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './OffreAchat.styles'

export const OffreAchat = ({ headerComponent, isHeaderScrolled, onClickSidebarButton }) => {
  const { contentComponent } = useContext(PostContentContext)

  return (
    <S.Page>
      <S.Header>{headerComponent}</S.Header>

      <S.Sidebar>
        <S.SidebarPurchaseOffer $isVisible={isHeaderScrolled}>
          <S.SidebarPurchaseOfferTitle>Générez gratuitement votre offre d’achat !</S.SidebarPurchaseOfferTitle>

          <p>
            Éditez en quelques clics votre <strong>offre d’achat immobilier</strong> personnalisée. <br />
            Claire et rassurante, elle présente les infos clés attendues par le vendeur.
          </p>

          <S.SidebarPurchaseOfferButton isSecondary isArrowShown onClick={onClickSidebarButton}>
            Générer mon offre d’achat
          </S.SidebarPurchaseOfferButton>

          <S.SidebarPurchaseOfferImage>
            <S.SidebarPurchaseOfferSheet />
            <S.SidebarPurchaseOfferLabel />
          </S.SidebarPurchaseOfferImage>
        </S.SidebarPurchaseOffer>
      </S.Sidebar>

      <S.Content>{contentComponent}</S.Content>
    </S.Page>
  )
}

OffreAchat.propTypes = {
  headerComponent: PropTypes.node.isRequired,
  isHeaderScrolled: PropTypes.bool.isRequired,
  onClickSidebarButton: PropTypes.func.isRequired,
}

import BaseInput from '@pretto/bricks/components/form/BaseInput'
import { shape } from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Image from '@pretto/bricks/website/shared/components/Image'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'

export const PurchaseOfferHeader = forwardRef(({ title, emailInputProps }, ref) => (
  <HeaderPurchaseOffer ref={ref}>
    <HeaderPurchaseOfferContent>
      <HeaderPurchaseOfferTitle>{title}</HeaderPurchaseOfferTitle>

      <p>
        Éditez en quelques clics votre <strong>offre d’achat immobilier</strong> personnalisée. <br />
        Claire et rassurante, elle présente les infos clés attendues par le vendeur.
      </p>

      <HeaderPurchaseOfferEmail {...emailInputProps} />
    </HeaderPurchaseOfferContent>

    <HeaderPurchaseOfferImage>
      <HeaderPurchaseOfferSheet />
      <HeaderPurchaseOfferLabel />
    </HeaderPurchaseOfferImage>
  </HeaderPurchaseOffer>
))

PurchaseOfferHeader.displayName = 'PurchaseOfferHeader'

PurchaseOfferHeader.propTypes = {
  title: PropTypes.string.isRequired,
  emailInputProps: PropTypes.object.isRequired,
}

const HeaderPurchaseOffer = styled.div`
  padding: ${g(4)} ${g(3)};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${g(4)};
  background-color: ${({ theme }) => theme.colors.primary1};
  position: relative;
  z-index: 1;
  border-radius: ${g(1)};
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(6)} 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${g(3)};
    justify-items: flex-end;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(7.5)} 0;
  }

  ${shape(
    css`
      width: 500px;
      height: 573px;
      background-size: 500px 573px;
      transition: 250ms transform ease-in-out;
      transform: translate(202px, 405px);
      top: initial;
      left: initial;
    `,
    {
      variant: 'primary2',
      mediaQueries: {
        laptop: css`
          width: 868px;
          height: 994px;
          background-size: 868px 994px;
          transform: translate(326px, 720px);
        `,
        desktop: css`
          transform: translate(294px, 650px);
        `,
      },
      hover: {
        laptop: css`
          transform: translate(334px, 730px);
        `,
        desktop: css`
          transform: translate(304px, 660px);
        `,
      },
    }
  )}
`
const HeaderPurchaseOfferContent = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2 / span 6;
  }
`
const HeaderPurchaseOfferTitle = styled.h1`
  ${typo.heading32};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading48};
    margin-bottom: ${g(3)};
  }
`
const HeaderPurchaseOfferEmail = styled(BaseInput)`
  margin-top: ${g(3)};
`
const HeaderPurchaseOfferImage = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    position: absolute;
    bottom: 0;
    right: ${g(9)};
    transform: translateY(100px);
    transition: transform 250ms ease-in-out;

    ${HeaderPurchaseOffer}:hover & {
      transform: translateY(84px);
    }
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    right: ${g(13)};
    transform: translateY(148px);

    ${HeaderPurchaseOffer}:hover & {
      transform: translateY(132px);
    }
  }
`
const HeaderPurchaseOfferSheet = styled(Image).attrs({
  options: { width: 320, height: 452 },
  path: 'v1657033180/zen/static-assets/decoration/sample-purchase-offer.png',
})`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    width: 240px;
    height: 339px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
    width: 320px;
    height: 452px;
  }
`
const HeaderPurchaseOfferLabel = styled(Image).attrs({
  options: { height: 112, width: 112 },
  path: 'v1637684805/zen/static-assets/illustrations/660x660/label.svg',
})`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    position: absolute;
    top: ${g(-2.5)};
    right: ${g(-2)};
    width: 85px;
    height: 85px;
    transform: translateY(0);
    transition: transform 250ms ease-in-out;

    ${HeaderPurchaseOffer}:hover & {
      transform: translateY(${g(-1.5)});
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      width: 112px;
      height: 112px;
      top: ${g(-2.5)};
      right: ${g(-3)};
    }
  }
`
